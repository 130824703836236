import { v4 as uuidv4 } from "uuid";

function chillHop() {
  return [
    {
      name: "Festa",
      artist: "Misha, Jussi Halme, Soul Food Horns",
      cover:
        "https://chillhop.com/wp-content/uploads/2021/05/2473c60e471fe9b40e246bf7711c87d3d6ea69a7-1024x1024.jpg",
      audio: "https://mp3.chillhop.com/serve.php/?mp3=12091",
      color: ["#2E385B", "#ED7B77"],
      id: uuidv4(),
      active: true,
    },
    {
      name: "Antematter",
      artist: "Toonorth",
      cover:
        "https://chillhop.com/wp-content/uploads/2021/05/732128e1da8fd5f6292ffc1926a2ea840a54f4d0-1024x1024.jpg",
      audio: "https://mp3.chillhop.com/serve.php/?mp3=17938",
      color: ["#B3CDF6", "#324861"],
      id: uuidv4(),
      active: false,
    },
    {
      name: "Slopes",
      artist: "Chillhop Music, Philanthrope, Yasper",
      cover:
        "https://chillhop.com/wp-content/uploads/2021/07/875b9624506ae8d05750b9e4ab4579abb1fe3e16-1024x1024.jpg",
      audio: "https://mp3.chillhop.com/serve.php/?mp3=21785",
      color: ["#97E0DB", "#8C3116"],
      id: uuidv4(),
      active: false,
    },
    {
      name: "travelbag",
      artist: "mommy, Sleepy Fish",
      cover:
        "https://chillhop.com/wp-content/uploads/2021/05/1245c0271290a3196328c0cf4aaa910cd873dfe7-1024x1024.jpg",
      audio: "https://mp3.chillhop.com/serve.php/?mp3=19058",
      color: ["#F4DEC7", "#5D92D4"],
      id: uuidv4(),
      active: false,
    },
    {
      name: "Vintage",
      artist: "Evil Needle",
      cover:
        "https://chillhop.com/wp-content/uploads/2021/04/cb0cc6270d7f2e1bb13e44e8832bd5c9b2a61080-1024x1024.jpg",
      audio: "https://mp3.chillhop.com/serve.php/?mp3=17088",
      color: ["#E5B350", "#EE5550"],
      id: uuidv4(),
      active: false,
    },
    {
      name: "Reflect",
      artist: "SwuM, Ben Belial",
      cover:
        "https://chillhop.com/wp-content/uploads/2021/03/74d62bc9370a68e440c1b98eaf650344f0a7faea-1024x1024.jpg",
      audio: "https://mp3.chillhop.com/serve.php/?mp3=15238",
      color: ["#AB6C2A", "#22302F"],
      id: uuidv4(),
      active: false,
    },
    {
      name: "Playful Obsession",
      artist: "C Y G N",
      cover:
        "https://chillhop.com/wp-content/uploads/2021/02/d12927eedcc2f5afba2ab049a4a1ea46c2266ae3-1024x1024.jpg",
      audio: "https://mp3.chillhop.com/serve.php/?mp3=14983",
      color: ["#90C8F5", "#7F49A5"],
      id: uuidv4(),
      active: false,
    },
    {
      name: "Aqueduct",
      artist: "Leavv",
      cover:
        "https://chillhop.com/wp-content/uploads/2020/09/88e7eb711f8c71d87fc102e97cf91e36f692348d-1024x1024.jpg",
      audio: "https://mp3.chillhop.com/serve.php/?mp3=9921",
      color: ["#8DCBB3", "#DF9264"],
      id: uuidv4(),
      active: false,
    },
    {
      name: "Creswick",
      artist: "Aviino",
      cover:
        "https://chillhop.com/wp-content/uploads/2020/10/23fdd99adc3e16abcb67b004ea3e748ebf433a49-1024x1024.jpg",
      audio: "https://mp3.chillhop.com/serve.php/?mp3=10454",
      color: ["#6BA7E2", "#C278BC"],
      id: uuidv4(),
      active: false,
    },
  ];
}

export default chillHop;
